const initialState = {
    adsData: [],
    adsStatus: null
}

const adsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCHADS':
            return {
                ...state,
                adsData: action.payload,
                adsStatus: 200
            }
        default:
            return state    
    }
}

export default adsReducer